import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private http: HttpClient) { }

  getData(filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}cities`, filter);
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.apiUrl}cities/create`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.apiUrl}cities/update/${data._id}`, data);
  }

  deleteData(data: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}cities/delete/${data._id}`);
  }

  getModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/get-exclusions?model=cities`);
  }

  updateModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-exclusions`, data);
  }

  getAllModel(): Observable<object> {
    return this.http.get<any>(`${environment.yiiUrl}agencies/is-all-check?model=cities`);
  }

  updateAllModel(data: any): Observable<object> {
    return this.http.post<any>(`${environment.yiiUrl}agencies/model-all-exclusions`, data);
  }
  post(modelUrl: string, form: any, remote?: any, options?: any): Observable<any> {
    let server: string;
    if (remote === 'nodejs') {
        server = environment.apiUrl;
    } else {
        server = environment.yiiUrl;
    }

    options = options ? options : {};

    return this.http.post<any>(`${server}${modelUrl}`, form, options);
}
uploadCsv(data, modelType): Observable<any> {
    return this.http.post(
        `${environment.apiUrl}cities/import-data`,
        { importData: data, modelType: modelType }
    );
}
getCitiesList(country: string): Observable<object> {
  return this.http.post<any>(`${environment.apiUrl}citiesList`, { country });
}


}
