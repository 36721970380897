import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';

// import * as moment from 'moment';
// import 'moment-timezone';
import moment from 'moment-timezone';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.access_token) {
            // https://gitlab.optimasit.com/arsl/optima-crm-v2/-/issues/2859
            /**
             * add this check if request from CRM it add the default header
             * else it add the empty header
             * 
             * NOTE 
             * why we add because when ever we call the google-translation API 
             * as the result we are logout from CRM
             * 
            */
            if (request.url.indexOf('immosurance') > -1 || request.url.indexOf('localhost') > -1 || request.url.indexOf('127.0.0.1') > -1) { 
                if (
                    request.url.indexOf('csv-account-uploader') > -1 ||
                    request.url.indexOf('properties/csv-properties-uploader') > -1 ||
                    request.url.indexOf('single-upload') > -1 ||
                    request.url.indexOf('attachments/uploader') > -1 ||
                    request.url.indexOf('xml-export-formats/csv-company-uploader') > -1 ||
                    request.url.indexOf('xml-export-formats/csv-contacts-uploader') > -1 ||
                    request.url.indexOf('users/upload-single') > -1 ||
                    request.url.indexOf('xml-export-formats/csv-owner-uploader') > -1 ||
                    request.url.indexOf('xml-export-formats/csv-timeline-uploader') > -1 ||
                    request.url.indexOf('cms-medias/uploader') > -1
                ) {
                    request = request.clone({});
                } else if (
                    request.url.indexOf('media/create') > -1 || request.url.indexOf('media/update') > -1
                ) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.access_token}`
                        }
                    });
                } else if (
                    request.url.indexOf('mail-attachment') > -1 ||
                    request.url.indexOf('translations/uploade-translations') > -1
                ) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.access_token}`
                        }
                    });
                } else if (request.url.indexOf(environment.apiUrl) > -1) {
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${currentUser.access_token}`,
                            'Time-Zone': moment.tz.guess(),
                        }
                    });
                } else {
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${currentUser.access_token}`
                        }
                    });
                }
            } else {
                request = request.clone({});
            }
        }
        return next.handle(request);
    }
}
