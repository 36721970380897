import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NationalitiesService {

  constructor(private http: HttpClient) { }

  getData(filter: any, page: number, perPage?: number): Observable<any[]> {
    return this.http.post<any[]>(`${environment.yiiUrl}nationalities/search?page=` + page + `&per-page=${perPage}`, filter, { observe: 'response' as 'body' })
    .pipe(map(data => {
      return data;
    }));
  }

  createData(data: any): Observable<object> {
    return this.http.post(`${environment.yiiUrl}nationalities`, data);
  }

  updateData(data: any): Observable<object> {
    return this.http.put(`${environment.yiiUrl}nationalities/${data._id}`, data);
  }

  deleteData(data: any): Observable<any> {
    return this.http.delete(`${environment.yiiUrl}nationalities/${data._id}?created_by=${data.created_by}`);
  }

  getListData(filter: any): Observable<object> {
    return this.http.post<any[]>(`${environment.apiUrl}nationalities`, filter);
  }

  getAllList(): Observable<object> {
    return this.http.get<any[]>(`${environment.apiUrl}nationalitiesList`);
  }
}
